/**
 * Performs an asynchronous HTTP request using the Fetch API.
 *
 * @param {string} url The URL to send the request to.
 * @param {Object} [options={}] The options for the fetch request, including method and headers.
 * @returns {Promise<Object>} A promise resolving to the JSON-decoded response.
 * @throws {Error} Throws an error if the fetch request fails or cannot be completed.
 */
async function apiFetch(url, options = {}) {
    options = {
        cache: 'no-cache',
        referrer: 'client',
        referrerPolicy: 'origin',
        headers: {
            'Content-Type': 'application/json',
            ...options.headers
        },
        ...options
    }

    try {
        const response = await fetch(url, options);

        return await response.json();
    } catch (error) {
        console.error('Fetch error:', error);

        throw error;
    }
}

/**
 * Performs a GET request to the specified URL.
 *
 * @param {string} url The URL to send the GET request to.
 * @param {Object} [options={}] Optional fetch options.
 * @returns {Promise<Object>} A promise that resolves with the result of the GET request.
 */
const get = async (url, options = {}) => apiFetch(url, {
    ...options,
    method: 'GET'
});

/**
 * Performs a POST request to the specified URL with JSON-encoded data.
 *
 * @param {string} url The URL to send the POST request to.
 * @param {Object} data The JSON-serializable data to be sent with the POST request.
 * @param {Object} [options={}] Optional fetch options.
 * @returns {Promise<Object>} A promise that resolves with the result of the POST request.
 */
const post = async (url, data, options = {}) => apiFetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(data)
});

export { get, post };