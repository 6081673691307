import '../scss/footer-menu.scss';
import { post } from './utils/api';

document.addEventListener('DOMContentLoaded', () => {
    const submitButton = document.querySelector('.footer-menu__button');

    handleToc();

    submitButton.addEventListener('click', (event) => {
        event.preventDefault();

        handleSubmit();
    });
});

/**
 * Handles the toggle of the Terms of Conditions (ToC) checkbox in the footer.
 * When the checkbox is checked, it enables the submit button; otherwise, it disables it.
 */
function handleToc() {
    const tocCheckbox = document.querySelector('.footer-menu__toc-checkbox');
    const submitButton = document.querySelector('.footer-menu__button');

    tocCheckbox.addEventListener('change', () => {
        submitButton.toggleAttribute('disabled');
    });
}

/**
 * Handles the newsletter form submission by sending the form data to the server and displaying success or error messages.
 */
function handleSubmit() {
    const newsletterForm = document.querySelector('#footer-newsletter-form');
    const alert = document.querySelector('.footer-menu__alert');

    const email = newsletterForm.querySelector('[name="email"]').value;
    const listId = newsletterForm.querySelector('[name="list_id"]').value;

    post('/api/v1/newsletters', {
        email: email,
        list_id: listId
    }).then(response => {
        if (response.status === 200) {
            newsletterForm.classList.add('d-none');
            alert.innerHTML = response.data.message;
            alert.classList.add('alert-success');
            alert.classList.remove('d-none');
        } else {
            newsletterForm.classList.add('d-none');
            alert.innerHTML = response.messages.message;
            alert.classList.add('alert-warning');
            alert.classList.remove('d-none');
        }

        setTimeout(() => {
            newsletterForm.classList.remove('d-none');
            alert.classList.add('d-none');
            alert.classList.remove('alert-success');
            alert.classList.remove('alert-warning');
            alert.innerHTML = '';
        }, 3000);
    });
}
